import dayJs from 'dayjs';
import 'dayjs/locale/zh-cn';
dayJs.locale('zh-cn');

const formatDate = (date, format) => {
	if (date) {
		return dayJs(date).format(format);
	}
	return '';
};

export {
	formatDate
}  