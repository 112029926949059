<template>
  <div class="wrap">
	<search-com v-if="useInfo.privateUserID" :is-hide="false"></search-com>
	<welfare-breadcrumb :itemBreadcrumb="itemBreadcrumb"></welfare-breadcrumb>
    <section class="con-body" v-if="tableData&&tableData.length>0">
      <!-- 表格 -->
	  <div v-for="tableItem,index in tableData" :key="index" class="car_table_box" v-show="tableItem.length>0">
		<el-table
			:row-class-name="tableRowClass"
			:header-cell-style="headClass"
			max-height="700"
			:ref="'multipleTable'+index"
			:data="tableItem"
			style="width: 100%;"
			@selection-change="(selections)=>{handleSelectionChange(selections,index)}"
			class="car_table">
			<el-table-column
				type="selection"
				width="55"
				:selectable="selectable">
			</el-table-column>
			<el-table-column label="商品信息" width="300">
				<template slot-scope="scope">
				<div class="info_wrap">
					<div class="img_box">
						<el-image style="width: 74px; height: 74px" fit="fit" :src="scope.row.mealUrl" :class="{'not_valid_img':!scope.row.isActive}"></el-image>
					</div>
					<div class="info_right">
						<div class="info_header" :class="!scope.row.isActive ? 'gray_class':'' ">{{scope.row.mealName}}</div>
						<div class="info_code" :class="!scope.row.isActive ? 'gray_class':'' ">
							{{scope.row.orderType == 1?'礼包编码：'+scope.row.mealID:'商品编码：'+scope.row.mealCode}}
						</div>
						<div class="tag" v-if="scope.row.orderType == 1">
							<el-tag type="info" size="small" :class="!scope.row.isActive ? 'not_valid_tags':'' ">{{scope.row.festivalName}}</el-tag>
						</div>
						<div class="tag" v-else>
							<el-dropdown trigger="click" @command="(command)=>{handleCommand(command,scope.row)}">
								<span class="el-dropdown-link">
									修改类型<i class="el-icon-arrow-down el-icon--right"></i>
								</span>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item command="2">企业集采
										<span style="display: block;">注：下单后送货至指定地址</span>
									</el-dropdown-item>
									<el-dropdown-item command="3">员工自领
										<span style="display: block;">注：下单后发放至员工自行领取</span>
									</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</div>
					</div>
				</div>
				</template>
			</el-table-column>
			<el-table-column
				label="类型">
				<template slot-scope="scope">
				<span v-if="scope.row.orderType == 1">礼包</span>
				<span v-else-if="scope.row.orderType == 2">企业集采</span>
				<span v-else-if="scope.row.orderType == 3">员工自领</span>
				</template>
			</el-table-column>
			<el-table-column
				label="单价">
				<template slot-scope="scope">
				<span>￥{{scope.row.mealPrice}}</span>
				</template>
			</el-table-column>
			<el-table-column
				prop="address"
				label="数量"
				min-width="150"
				show-overflow-tooltip>
				<template slot-scope="scope">
				<el-input-number v-model.number="scope.row.mealCount"  :min="1" @change="changeNum(scope.row)"  @input.native="preventPoint" size="small" :disabled="!scope.row.isActive" autocomplete="off"></el-input-number>
				</template>
			</el-table-column>
			<el-table-column label="金额">
				<template slot-scope="scope">
				<span>￥{{scope.row.amount}}</span>
				</template>
			</el-table-column>
			<el-table-column
				label="操作">
				<template slot-scope="scope">
				<el-button type="text" @click="delBtn(scope.row)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<!-- 固定脚部 -->
		<div class="fix_footer">
			<div class="footer_text">
				<p>*提交{{index ==0 ?'礼包':'商品'}}订单必须登录账号、上传企业信息和完成企业认证才能购买</p>
			</div>
			<div class="bottom_box" v-if="index == 0">
				已选{{copyNum1}}件，合计<span class="red">￥<span class="fz">{{totalPrice1}}</span></span>
				<el-button type="danger" round @click="SubmitBtn(index)">提交</el-button>
			</div>
			<div class="bottom_box" v-else-if="index == 1">
				已选{{copyNum2}}件，合计<span class="red">￥<span class="fz">{{totalPrice2}}</span></span>
				<el-button type="danger" round @click="SubmitBtn(index)">提交</el-button>
			</div>
			<div class="bottom_box" v-else-if="index == 2">
				已选{{copyNum3}}件，合计<span class="red">￥<span class="fz">{{totalPrice3}}</span></span>
				<el-button type="danger" round @click="SubmitBtn(index)">提交</el-button>
			</div>
		</div>
	  </div>
	</section>
	<!-- 购物车为空 -->
    <section class="con-body" v-else>
      <div class="shopping_car_empty">
		  <img class="img" src="~/assets/img/welfareMall/cartNo_bg.png" alt="">
        <div ></div>
      </div>
    </section>

	<!-- 删除弹框 -->
	<confirm-dialog 
		:title="delDialog.title"
		:dialog-width="delDialog.width"
		:show.sync="delDialog.show"
		@handle-confirm="delDialogConfirm"
		class="dialog_del">
		<span slot="content">
			<div>
				<span style="display: block; justify-content: center; padding: 30px;">确定删除{{orderType==1?'礼包':'商品'}}：
				<span>{{mealName}}</span>吗？</span>
			</div>
		</span>
	</confirm-dialog>

	<!-- 企业认证弹框 -->
	<confirm-dialog 
		:title="authDialog.title"
		:dialog-width="authDialog.width"
		:show.sync="authDialog.show"
		:confirm-text="authDialog.text"
		@handle-confirm="authDialogConfirm"
		class="dialog_auth">
		<span slot="content">
			<div>
				<span style="display: flex; justify-content: center; padding: 30px;">
					<span>您的企业还
						<span style="color: #FA8C16">未认证</span>，请认证完成后提交订单。</span>
				</span>
			</div>
		</span>
	</confirm-dialog>

 	<!-- 订单弹框 -->
	<confirm-dialog 
		:title="orderDialog.title"
		:confirm-text="orderDialog.confirmText"
		:dialog-width="orderDialog.width"
		:show.sync="orderDialog.show"
		@handle-confirm="orderDialogConfirm"
		@update:show="orderDialogUpdate"
		class="dialog_order">
		<span slot="content">
			<div>
				<div class="top_box" v-if="orderType == 2">
					<el-button type="success" size="small" style="background:#0089cd;border:1px solid #0089cd;color:#fff;" @click="addAddressBtn">新增地址</el-button>
					<div class="address_box">
						<div class="label_text">收货地址：</div>
						<div class="address_inf">
							{{defaultAssressInfo.province}}{{defaultAssressInfo.city}}{{defaultAssressInfo.region}}{{defaultAssressInfo.town}}{{defaultAssressInfo.detailAddress}}
							<p>{{defaultAssressInfo.name}}<span>{{defaultAssressInfo.phone}}</span></p>
						</div>
					</div>
				</div>
				<div class="table_box">
					<div class="table_box_head">
						<div class="current_company">
							当前企业：
							<div class="img_box">
								<img :src="this.companyInfo.userAvatarUrl" style="width:56px;height:56px;border-radius: 50%;"/>
								<span class="Auth">{{this.companyInfo.auditStatus == '2'?'已认证': this.companyInfo.auditStatus == '1'?'认证中':'未认证'}}</span>
							</div>
							{{this.companyInfo.companyName}}
						</div>
						<div class="type_header" v-if="orderType == 1"><span>类型</span>：节日礼包</div>
						<div class="type_header" v-else-if="orderType == 2"><span>类型</span>：企业集采</div>
						<div class="type_header" v-else-if="orderType == 3"><span>类型</span>：员工自领</div>
					</div>
					<!-- 表格 -->
					<el-table 
					 :data="mutipSelectArray" 
					 :header-cell-style="headClass" 
					 border
					 style="width: 100%;background:#F4F7FC;" 
					 max-height="318">
						<el-table-column prop="mealName" :label="orderType == 1 ?'礼包名称':'商品名称'" width="225"></el-table-column>
						<el-table-column label="单价" align="center">
							<template slot-scope="scope">
								<span>￥{{scope.row.mealPrice}}</span>
							</template>
						</el-table-column>
						<el-table-column prop="mealCount" :label="orderType == 1 ?'份数':'数量'" align="center"></el-table-column>
						<el-table-column :label="orderType == 1 ?'礼包金额':'总金额'" align="center">
							<template slot-scope="scope">
								<span>￥{{scope.row.amount}}</span>
							</template>
						</el-table-column>
						<el-table-column label="礼包有效期" v-if="orderType == 1" align="center">
							<template slot-scope="scope">
								<span>{{scope.row.startTime2}}-{{scope.row.endTime2}}</span>
							</template>
						</el-table-column>
					</el-table>
					<div class="coupon_box">
						选择优惠券：
						<el-select v-model="couponId" @change="changeCouponId" placeholder="请选择优惠券" style="width:300px;">
							<el-option
								v-for="item in couponList"
								:key="item.couponId"
								:label="item.jianStr"
								:value="item.couponId"
								:disabled="item.isDisabled">
							</el-option>
						</el-select>
					</div>
				</div>
				<!-- 固定 -->
				<div class="fix_footer_box" v-if="orderType == 1">
					<div class="item_info">
						礼包数：<span class="fw">{{copyNum1}}</span>
					</div>
					<div class="item_info">
						总份数：<span class="fw">{{totalNum1}}</span>
					</div>
					<div class="item_info">
						合计：<span class="red">￥</span> <span class="red fw"> {{totalPrice1 - couponAmount}}</span>
					</div>
				</div>
				<div class="fix_footer_box" v-else-if="orderType == 2">
					<div class="item_info">
						商品数：<span class="fw">{{copyNum2}}</span>
					</div>
					<div class="item_info">
						总商品数量：<span class="fw">{{totalNum2}}</span>
					</div>
					<div class="item_info">
						合计：<span class="red">￥</span> <span class="red fw"> {{totalPrice2 - couponAmount}}</span>
					</div>
				</div>
				<div class="fix_footer_box" v-else-if="orderType == 3">
					<div class="item_info">	
						商品数：<span class="fw">{{copyNum3}}</span>
					</div>
					<div class="item_info">
						总商品数量：<span class="fw">{{totalNum3}}</span>
					</div>
					<div class="item_info">
						合计：<span class="red">￥</span> <span class="red fw"> {{totalPrice3 - couponAmount}}</span>
					</div>
				</div>
			</div>
		</span>
	</confirm-dialog>

	<!-- 收货地址弹窗 -->
	<confirm-dialog
		class="add_addres_dialog addAddress"
		:title="addAddressDialog.title"
		:dialog-width="addAddressDialog.width"
		:show.sync="addAddressDialog.show"
		:is-show="false">
		<span slot="content">
			<div class="top_box">
				<div class="box_header"><span class="spot"></span><span>新增收货地址</span></div>
				<el-form :model="addAddressForm" :rules="addAddressFormRule" ref="addAddressFormRef" label-width="120px" class="demo-ruleForm">
					<el-form-item label="地址信息：" prop="addressValue">
						<el-cascader style="width:565px;" placeholder="请选择地址信息" ref="cascader" v-model="addAddressForm.addressValue" :props="addressProps" @change="handleChangeAddress"></el-cascader>
					</el-form-item>
					<el-form-item label="详细地址：" prop="detailAddress">
						<el-input v-model="addAddressForm.detailAddress" placeholder="请输入详细地址，如道路，门牌号、小区、楼栋号、单元等信息"></el-input>
					</el-form-item>
					<el-form-item label="收货人姓名：" prop="name" >
						<el-input v-model="addAddressForm.name" maxlength="25" show-word-limit placeholder="请输入收货人姓名，长度不超过25个字符"></el-input>
					</el-form-item>
					<el-form-item label="手机号码：" prop="phone">
						<el-input v-model="addAddressForm.phone" placeholder="请输入11位手机号码"></el-input>
					</el-form-item>
				</el-form>
				<div style="display: flex;justify-content:center;margin-bottom:30px;color:#fff;">
					<el-button type="primary" @click="saveAddressBtn">保存地址</el-button>
				</div>
			</div>
			<div class="bottom_box" >
				<div class="box_header"><span class="spot"></span><span>地址信息</span></div>
				<el-table :data="addressList" height="300">
					<el-table-column prop="name" label="收货人" fixed></el-table-column>
					<el-table-column label="所在地区">
						<template slot-scope="scope">
							<span>{{scope.row.province}}{{scope.row.city}}{{scope.row.region}}{{scope.row.town}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="detailAddress" label="详细地址"></el-table-column>
					<el-table-column prop="phone" label="手机号" width="120"></el-table-column>
					<el-table-column label="操作" width="250" fixed="right">
						<template slot-scope="scope">
							<el-button type="text" size="small" style="color:#409eff;" @click="editAddressBtn(scope.row)">修改</el-button>
							<el-button type="text" size="small" style="color:#409eff;"  @click="delAddressBtn(scope.row)">删除</el-button>
							<el-button type="text" size="small" style="color:#409eff;" :disabled="scope.row.isDefault" @click="setDefaultAddBtn(scope.row)">设为当前地址</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</span>
	</confirm-dialog>

	<!-- 删除地址弹框 -->
	<confirm-dialog 
	:title="delAddDialog.title"
	:dialog-width="delAddDialog.width"
	:show.sync="delAddDialog.show"
	@handle-confirm="delAddDialogConfirm"
	class="dialog_del">
		<span slot="content">
			<div>
				<span style="display: flex; justify-content: center; padding: 30px;">是否删除该地址？</span>
			</div>
		</span>
	</confirm-dialog>

	<!-- 修改地址弹窗 -->
	<confirm-dialog 
	:title="editAddDialog.title"
	:dialog-width="editAddDialog.width"
	:show.sync="editAddDialog.show"
	@handle-confirm="editAddDialogConfirm"
	class="dialog_del">
	<span slot="content">
		<div>
			<el-form :model="editAddressForm" :rules="addAddressFormRule" ref="editAddressFormRef" label-width="120px" class="demo-ruleForm">
				<el-form-item label="地址信息：" prop="addressValue">
					<el-cascader style="width:440px;" placeholder="请选择地址信息" ref="cascader" v-model="editAddressForm.addressValue" :props="addressProps" @change="handleChangeEditAddress"></el-cascader>
				</el-form-item>
				<el-form-item label="详细地址：" prop="detailAddress">
					<el-input v-model="editAddressForm.detailAddress" placeholder="请输入详细地址，如道路，门牌号、小区、楼栋号、单元等信息"></el-input>
				</el-form-item>
				<el-form-item label="收货人姓名：" prop="name" >
					<el-input v-model="editAddressForm.name" maxlength="25" show-word-limit placeholder="请输入收货人姓名，长度不超过25个字符"></el-input>
				</el-form-item>
				<el-form-item label="手机号码：" prop="phone">
					<el-input v-model="editAddressForm.phone" placeholder="请输入11位手机号码"></el-input>
				</el-form-item>
			</el-form>
		</div>
	</span>
	</confirm-dialog>

	<!-- 协议弹框 -->
	<confirm-dialog 
		:title="certifiedDialog.title"
		:dialog-width="certifiedDialog.width"
		:show.sync="certifiedDialog.show"
		:confirm-text="certifiedDialog.text"
		@handle-confirm="certifiedDialogConfirm"
		class="dialog_auth">
		<span slot="content">
			<div>
				<span style="display: flex; justify-content: center; padding: 30px;">
					<span>您的企业还
						<span style="color: #FA8C16">未签署【节日福利】协议</span>，请签署协议后提交订单。</span>
				</span>
			</div>
		</span>
	</confirm-dialog>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import { getReqCompanyVerify ,getReqCompanyCertInfo,getCompanyCertOrSimpleInfo, getReqMyCart, getReqOrder, getReqCountChange } from '@/network/welfareMall_api'
import { getChangeType,getAddress,getSelectCityLoad,saveAddress,delAddress,getReqOrderEnterprie,getCoupon,getWelfareStatus } from '@/network/welfareMall_api';
import WelfareBreadcrumb from '@/components/welfareMallComp/WelfareBreadcrumb.vue';
import ConfirmDialog from '@/components/publicCom/ConfirmDialog.vue';
import { formatDate } from '@/plugins/utils';
import SearchCom from '@/components/welfareMallComp/SearchCom.vue';
export default {
  components: { WelfareBreadcrumb, ConfirmDialog, SearchCom },
  name: 'WelfareShoppingCart',
  data: function () {
	let _this2 = this;
	/**校验手机号码格式*/
	let validatePhone = function (rule, value, callback) {
		let mobileReg = /^1[3456789]\d{9}$/;
		if (!value) {
				return callback(new Error('请输入联系人手机号'));
		} else {
			if (!mobileReg.test(value.trim())) {
				return callback(new Error('请输入正确的手机号'));
			} else {
				return callback()
			}
		}
	};
	let validateAddress = function(rule,value,callback){
		let addressReg = /^[\u4E00-\u9FA5A-Za-z0-9]+$/;
		//console.log(!addressReg.test(value.trim()))
		if (!value) {
				return callback(new Error('请输入地址'));
		} else {
			if (!addressReg.test(value.trim())) {
				return callback(new Error('请输入正确的地址'));
			} else {
				return callback()
			}
		}
	}
    return {
		itemBreadcrumb: [
			{
				name: '福利商城',
				to: '/'
			},
			{
				name: '购物车',
			}
		],
		// 表格 购物车 二维数组
		tableData: [],
		multipleSelection: [],
		// 计数器
		num:'1',
		// 购物车id 
		myCartId:'',
		// 购物车数量
		mealCount:'',
		// 购物车的总数
		total:0,
		mutipSelectArray: [],//礼包 1
		// 提交订单
		orderDialog:{
			title: '提交订单',
			width: '770px',
			show: false,
			confirmText: '提交订单'
		},
		// 弹框 详情表格
		tableDataDetail:[],
		// 礼包份数
		copyNum1:0,
		copyNum2:0,
		copyNum3:0,
		// 提交订单 购物车ids
		cartIds:'',
		// 删除订单
		delDialog:{
			title:'删除提醒',
			width:'450px',
			show:false
		},
		// 企业认证
		authDialog:{
			title:'提示',
			width:'450px',
			text:'去认证',
			show:false
		},
		// 删除地址弹窗
		delAddDialog: {
			title:'提示',
			width:'450px',
			show:false
		},
		// 修改地址弹窗
		editAddDialog: {
			title:'修改收货地址',
			width:'600px',
			show:false
		},
		// 添加地址弹窗
		addAddressDialog:{
			title:'新增收货地址',
			width:'725px',
			show:false
		},
		// 添加地址 表单
		addAddressForm:{
			addressValue: [],
			detailAddress: '',
			name: '',
			phone: '',
			province: '', //省
			city: '', //市
			region: '', //区
			town: '' //镇
		},
		addAddressFormRule:{	
			addressValue : [
				{ required: true, message: '请选择地址信息', trigger: 'change' }
			],
			detailAddress: [
				{ required: true, message: '请输入详细地址', trigger: 'blur' },
				{ min: 5,max: 120, message: '长度在5 到120个字符', trigger: 'blur' },
				{ required: true,validator: validateAddress, trigger: 'blur' }
			],
			name: [
				{ required: true, message: '请输入收货人姓名', trigger: 'blur' },
			],
			phone: [
				{ required: true,validator: validatePhone, trigger: 'blur' }
			]
		},
		// 新增地址级联框
		addressProps: {
			lazy: true,
			lazyLoad(node, resolve){
				_this2.selectLoadLoad(node, resolve)
			}
		},
		addressList: [], //弹窗收获地址列表
		mealName:'',//礼包名称
		isDeleted:0,  // 0 否 1 是
		dealDialog: {
			title: '用户协议',
			width: '1000px',
			show: false,
			isShow: false
		},
		checked: false,
		dealDialogBtnText: '支付订单',
		dealDialogBtnTime: 20,
		dealDialogBtnDisabled: false,
		status: false, //判断是否进行企业认证 false 未认证
		selectList1: [], // 表头选中的值 orderType 1 2 3
		selectList2: [],
		selectList3: [],
		orderType: 0, // 1 礼包 2 企业集采 3 员工自领
		defaultAssressInfo: {}, // 默认地址的这一行数据
		lock: false,
		delAddressId: '',  //删除地址id
		editAddressForm: {
			addressValue: [],
			detailAddress: '',
			name: '',
			phone: '',
			province: '', //省
			city: '', //市
			region: '', //区
			town: '' //镇
		}, // 编辑地址表单
		userData:[],
		CompanyCertOrSimpleInfo: {},
		//添加优惠券功能
		couponList: [],//优惠券list
		couponId: '',//优惠券ID
		couponAmount: 0,//订单类型-所选优惠券金额
		IsCertified:false,//是否签署节日福利协议
		// 节日福利协议
		certifiedDialog:{
			title:'提示',
			width:'450px',
			text:'去签署',
			show:false
		},
    }
  },
	created() {
		let sessionUseInfo = JSON.parse(sessionStorage.getItem('useInfo'))
		if(sessionStorage.getItem('myCartList') && !this.useInfo.privateUserID){
			this.CarList();
		}else if(this.useInfo.privateUserID){//判断是否登录
			//在页面刷新时将vuex里的信息保存到sessionStorage里
			window.addEventListener("beforeunload",()=>{
				sessionStorage.setItem("useInfo",JSON.stringify(this.useInfo))
			})
			this.getCatList();
			// 判断公司是否认证
			this.getFlag();
			this.getStatus();//获取是否签署协议
			return;
		}
		//页面刷新 判断
		if(sessionUseInfo && sessionUseInfo.privateUserID){
			setTimeout(()=>{
				if(this.userData.privateUserID){
					this.getCatList();
					// 判断公司是否认证
					this.getFlag();
					this.getStatus();//获取是否签署协议
				}
			},1000)
		}
  },
  watch: {
  	watchUseInfo(){
		if(this.$store.state.useInfo){
			this.userData = this.$store.state.useInfo;
			this.getCatList();
			this.getFlag();
			this.getStatus();//获取是否签署协议
		}
	},
  },
  computed: {
	// 映射state
	...mapState(['useInfo', 'companyInfo']),
	watchUseInfo(){
      return this.$store.state.useInfo;
    },
	/* 礼包 */
	// 总价
	totalPrice1 () {
		var totalPrice1 = 0;
		// var mutipSelectArray = this.mutipSelectArray
		let selectList1 = this.selectList1;
		selectList1.forEach((item, index)=>{
			totalPrice1 += item.mealPrice * item.mealCount;
		});
		return totalPrice1.toFixed(2);
	},
	// 总数  件数
	totalNum1(){
		var totalNum1 = 0;
		let selectList1 = this.selectList1;
		selectList1.forEach((item, index)=>{
			totalNum1 +=  item.mealCount;
		});
		return totalNum1;
	},
	/* 企业集采 */
	// 总价
	totalPrice2 () {
		var totalPrice2 = 0;
		let selectList2 = this.selectList2;
		selectList2.forEach((item, index)=>{
			totalPrice2 += item.mealPrice * item.mealCount;
		});
		return totalPrice2.toFixed(2);
	},
	// 总数  件数
	totalNum2(){
		var totalNum2 = 0;
		let selectList2 = this.selectList2;
		selectList2.forEach((item, index)=>{
			totalNum2 +=  item.mealCount;
		});
		return totalNum2;
	},
	/* 员工自领 */
	// 总价
	totalPrice3 () {
		var totalPrice3 = 0;
		let selectList3 = this.selectList3;
		selectList3.forEach((item, index)=>{
			totalPrice3 += item.mealPrice * item.mealCount;
		});
		return totalPrice3.toFixed(2);
	},
	// 总数  件数
	totalNum3(){
		var totalNum3 = 0;
		let selectList3 = this.selectList3;
		selectList3.forEach((item, index)=>{
			totalNum3 +=  item.mealCount;
		});
		return totalNum3;
	},
  },
  
  methods: {
	// 设置表头样式
	headClass(){
		return "color:#333333;background:#ECF6FF;"
	},
	// 置灰样式
	tableRowClass({row}){
		if(!row.isActive){
			return 'gray_class';
		}
		return ''
	},
	// 商品过期 多选框禁选
	selectable(row){
		if(row.isActive){ //商品没过期 可以勾选
			return true;
		}else{
			return false;
		}
	},
	// 计数器 只输入整数
	preventPoint(e) {
		this.$message.closeAll();
		// 验证是否是纯数字
		let isNumber = /^\d*$/.test(e.target.value);
		// 过滤非数字
		e.target.value = e.target.value.replace(/\D/g, "");
		if (!isNumber || e.target.value < 0) {
			this.$message.warning("只能输入正整数");
		}
		e.target.value = (e.target.value >= 0 && e.target.value.match(/^\d*/g)[0]) || 1;
	},

	// 修改类型 确定按钮
	async handleCommand(command,row){
		//console.log(command,row)
		let myCartId = '';
		if(this.useInfo.privateUserID){
			myCartId = row.myCartId;
		}else{
			myCartId = row.mealID;
		}
		let res = await getChangeType(myCartId,command);
		if(res.isSuccess){
			this.isShowPop = false;
			this.getCatList();
			this.$message.success(res.msg);
		}else{
			this.$message.error('您还没登录，即将为您跳转到登录页面');
			setTimeout( () =>{
				window.open(`${this.preUrlHome}`, '_blank');
			},1000)
		}
	},

	// 未登录时购物车
	CarList(){
		if(sessionStorage.getItem('myCartList') && !this.useInfo.privateUserID){
			let tableList = JSON.parse(sessionStorage.getItem('myCartList'));
			//console.log(tableList)
			let tableData1 = [];
			let tableData2 = [];
			let tableData3 = [];
			tableList.forEach(function (item, index) {
				if(item.orderType == 1){ //礼包
					tableData1.push(item)
				}else if(item.orderType == 2){ //企业集采
					tableData2.push(item)
				}else if(item.orderType == 3){ //员工自领
					tableData3.push(item)
				}
			})
			if(tableData1.length==0&&tableData2.length==0&&tableData3.length==0){
				this.tableData =[];
			}else{
				this.tableData = [tableData1,tableData2,tableData3];
			}
		}
	},

	// 判断是否已认证AA
	async getFlag() {
		const res = await getCompanyCertOrSimpleInfo();
		if (res.code==200) {
				let CompanyCertOrSimpleInfo = res.data;
				if(CompanyCertOrSimpleInfo.processStatus == 3){
					this.status = true;
				}else{
					this.status = false;
				}
				this.CompanyCertOrSimpleInfo = CompanyCertOrSimpleInfo;
		} else if(res.code==403){
			console.log(res.msg)
		}else {
			this.$message.warning(res.msg);
			return false;
		}
	},
	// 点击企业认证的弹窗的确定按钮 跳转到认证的页面
	async authDialogConfirm(){
		this.goSimpleAuthPage(this.companyInfo.userID, this.CompanyCertOrSimpleInfo.processStatus);
		this.authDialog.show = false;
	},

	// 获取用户是否签署节日福利协议
	async getStatus(){
		let res = await getWelfareStatus();
		//console.log(res)
		let statusResult = res.data;
		if(statusResult.status == 2||statusResult.status == 3 ){
			this.IsCertified = false;
		}else if(statusResult.status == 1){
			this.IsCertified = true;
		}else{
			console.log(statusResult);
		}
		//console.log(this.IsCertified)
	},
	// 点击协议的弹窗的确定按钮 跳转到协议的页面
	certifiedDialogConfirm(){
		window.open(`${this.preUrlOld}/Contract/Index`);
		this.certifiedDialog.show = false;
	},

	// 提交按钮 
	SubmitBtn(orderType){
		this.orderType = orderType+1;
		if(orderType == 0 ){
			if(this.selectList1.length > 0){
				this.mutipSelectArray = this.selectList1;
			}else{
				this.$message.warning('还没选择礼包哦');
				return;
			}			
		}else if(orderType == 1){
			if(this.selectList2.length > 0){
				this.mutipSelectArray = this.selectList2;
			}
			else{
				this.$message.warning('还没选择商品哦');
				return
			}
		}else if(orderType == 2 ){
			if(this.selectList3.length > 0){
				this.mutipSelectArray = this.selectList3;
			}else{
				this.$message.warning('还没选择商品哦');
				return
			}
		}
		if(!this.useInfo.privateUserID) { //1.判断是否登录  没登录 提示 跳转到登录页面
			this.$bus.$emit('openLoginDialogCom');
		}else if(this.useInfo.privateUserID){  //已经登录
			if(this.useInfo.companyList.length == 0){  //已经登录账号 没有公司
				return this.$message({
					type:'warnning',
					message: '您的账号还没添加企业',
				})
			}
			else if(this.status == false){  //2.1  企业没认证 弹出认证的弹窗
				this.authDialog.show = true
			}else if(this.IsCertified == false){  // 判断是否签署节日福利协议
				this.certifiedDialog.show = true;
			}else {  	//2.2 已经登录公司  已经认证  提交订单的弹窗显示
				this.orderDialog.show = true;
				orderType = orderType+1
				this.getCouponData(this['totalPrice'+ (orderType)]);
				this.getAddressList();
			}
		}
	},

	// 订单dialog关闭回调函数 清空优惠券 id 优惠金额
	orderDialogUpdate(){
		this.couponList = [];
		this.couponId = '';
		this.couponAmount = 0;
	},
	// 选择优惠券change事件
	changeCouponId(val){
		if (!val) {
				this.couponAmount = 0;//选择为空时 初始化0
				return;
		};
		let couponList = this.couponList;
		couponList.forEach(item=>{
			if(item.couponId === val){
				this.couponAmount = item.couponAmount;//优惠券面额（保存用于页面自行计算）
			}
		})
	},
	// 获取当前用户可使用优惠券
	async getCouponData(totalPayCopy){
		// console.log(totalPayCopy)
		let res = await getCoupon(7,'PC');
		if(res.code == 200){
			let couponList = res.data;
			let disAbleList = [];  // 优惠券禁用
			let sortList = []; // 优惠券选项排序
			if(couponList.length !== 0){
				// 渲染优惠卷下拉列表
				couponList.forEach(function (item, index) {
					if (item.baseAmount > totalPayCopy){
						item.isDisabled = true;
						disAbleList.push(item);
					}else{
						item.isDisabled = false;
						sortList.push(item);
					}
				})
				sortList.push(...disAbleList);
				// 渲染数据
				sortList.forEach(function (item, index) {
					item.jianStr = `满${item.baseAmount}减${item.couponAmount}（不可叠加）`;
				})
				this.couponList = sortList;
			}else{
				this.couponList = [{jianStr:'暂无可用优惠券',isDisabled: true}]
			}
		}else if(res.code==403){
			console.log(res.msg);
		}else{
			this.$message.error(res.msg);
		}
	},

	
	
	// 删除按钮 弹框显示
	delBtn(row){
		this.delDialog.show = true;
		this.mealID = row.mealID;
		this.myCartId = row.myCartId;
		this.orderType = row.orderType;
		this.mealCount = 0;
		this.mealName = row.mealName;
		this.isDeleted = 1;
	},
	// 删除弹框确定按钮
	delDialogConfirm(){
		if(!this.GetTokenFromCookie('pcToken') && sessionStorage.getItem('myCartList')){
			var tableData = this.tableData;
			//console.log(tableData)
			//console.log(this.mealID,this.orderType)
			let index = this.orderType - 1;
			let tableList = this.tableData[index];
			tableList.forEach((item, index)=>{
				if(item.mealID === this.mealID && item.orderType === this.orderType){
					tableList.splice(index, 1);
				}
			});
			this.tableData[index] = tableList;
			//将二维转化成一维存储
			function flatten(arr) { return [].concat( ...arr.map(x => Array.isArray(x) ? flatten(x) : x) ) }
			let tableData2 = flatten(tableData);
			//console.log(tableData)
			this.tableData = tableData;
			sessionStorage.setItem('myCartList', JSON.stringify(tableData2));
			this.$store.dispatch('getNoLoginCartNum');
			this.delDialog.show = false;
			this.$message.success('删除成功');
			return;
		}
		this.delDialog.show = false;
		this.carAddOrSub();
	},
	// 购物车的改变
	changeNum(row){
		//console.log(row)
		if(!this.GetTokenFromCookie('pcToken') && sessionStorage.getItem('myCartList')){
			let tableData = this.tableData;
			let index = row.orderType -1;
			//console.log(tableData[index])
			let tableList = tableData[index];
			tableList.forEach(function(item,index){
				if(item.mealID === row.mealID){
					item.amount = (item.mealCount * item.mealPrice);
				}
			})
			//console.log(tableList)
			tableData[index] = tableList;
			//将二维转化成一维存储
			function flatten(arr) { return [].concat( ...arr.map(x => Array.isArray(x) ? flatten(x) : x) ) }
			let tableData2 = flatten(tableData);
			//console.log(tableData2)
			this.tableData = tableData;
			sessionStorage.setItem('myCartList',JSON.stringify(tableData2));
			return;
		}
		this.myCartId = row.myCartId;
		this.mealCount = row.mealCount;
		// this.mealName = row.mealName;
		this.isDeleted = 0;
		this.carAddOrSub();
	},
	// 全选按钮
	handleSelectionChange(selections,param){
		//console.log(selections,param)
		if(param == 0){
			this.selectList1 = selections;
		}else if(param == 1){
			this.selectList2 = selections;
		}else if(param == 2){
			this.selectList3 = selections;
		}
		this.copyNum1 = this.selectList1.length;
		this.copyNum2 = this.selectList2.length;
		this.copyNum3 = this.selectList3.length;
	},	
	
	// 提交订单 确定按钮
	orderDialogConfirm(){
		let isAddress = Object.keys(this.defaultAssressInfo).length == 0;
		if(isAddress && this.orderType == 2){
			this.$message.error('您还未填写收货地址')
			return;
		}
		this.orderDialog.show = false;
		let arr = [];
		let mutipSelectArray = this.mutipSelectArray;
		mutipSelectArray.forEach(function(item){
			arr.push(item.myCartId);
		})
		this.cartIds = arr.join(";");
		if(this.orderType == 2){
			this.submitOrder2();
		}else{
			this.submitOrder();
		}
	},
	// 查看我的购物车
	async getCatList () {
		let selectList1 = this.selectList1;
		let selectList2 = this.selectList2;
		let selectList3 = this.selectList3;
		const res = await getReqMyCart();
		if(res.code==200){
			let _this = this;
			let tableList = res.data;
			let tableData1 = [];
			let tableData2 = [];
			let tableData3 = [];
			tableList.forEach(function (item, index) {
				if(item.orderType == 1){ //礼包
					tableData1.push(item);
				}else if(item.orderType == 2){ //企业集采
					tableData2.push(item);
				}else if(item.orderType == 3){ //员工自领
					tableData3.push(item);
				}
				item.amount = item.amount.toFixed(2);
				item.mealPrice = item.mealPrice.toFixed(2);
				let date = new Date(item.endTime).getTime() - new Date().getTime();
				item.startTime = item.startTime ? _this.$moment(item.startTime).format('YYYY-MM-DD') : '';
				item.endTime = item.endTime ? _this.$moment(item.endTime).format('YYYY-MM-DD') : '';
				item.startTime2 = item.startTime.replace(/-/g, '.');
				item.endTime2 = item.endTime.replace(/-/g, '.');
				if(date > 0){
					item.isValid = true;
				}else{
					item.isValid = false;
					item.isActive = false;
				}
			})
			let tableData;
			if(tableData1.length==0&&tableData2.length==0&&tableData3.length==0){
				tableData =[];
			}else{
				tableData = [tableData1,tableData2,tableData3];
			}
			this.tableData = tableData;
			// 获取的数据为 rows,获取完数据调用 
			// 保持原来已勾选中的商品数据
			this.$nextTick(function(){
				tableData.forEach(function (item) {
					item.forEach(function (cItem) {
						if(cItem.orderType == 1){ //礼包
							selectList1.forEach(function (ctem2) {
								if(cItem.myCartId === ctem2.myCartId){
									_this.$refs.multipleTable0[0].toggleRowSelection(cItem, true)
								}
							})
						}else if(cItem.orderType == 2){ //企业集采
							selectList2.forEach(function (ctem2) {
								if(cItem.myCartId === ctem2.myCartId){
									_this.$refs.multipleTable1[0].toggleRowSelection(cItem, true)
								}
							})
						}else if(cItem.orderType == 3){ //员工自领
							selectList3.forEach(function (ctem2) {
								if(cItem.myCartId === ctem2.myCartId){
									_this.$refs.multipleTable2[0].toggleRowSelection(cItem, true)
								}
							})
						}
					})
				})
			})
		}
	},
	// 礼包数量购物车的加减/删除
	async carAddOrSub(){
		const res = await getReqCountChange({cartId: this.myCartId, quantity: this.mealCount, isDeleted: this.isDeleted});
		if(res.isSuccess){
			if(this.mealCount == 0){
				this.$message.success('删除成功');
			}else{
				this.$message.success('购物车加减成功');
			}
			this.getCatList();
		}else{
			this.$message.error(res.msg);
		}
	},
	// 提交订单
	async submitOrder(){
		// return
		const res = await getReqOrder({cartIds: this.cartIds,couponId:this.couponId});
		if(res.isSuccess) {
			// 提交完订单之后重新调获取购物车列表数据
			this.getCatList();
			// 跳转到我的订单页面 
			window.open(`${this.preUrlOld}/HolidayWelfare/BillPay?official_jrfl1`,'_blank');
		}else{
			this.$message.error('提交订单失败');
		}
	},
	// 提交订单 企业集采
	async submitOrder2(){
		let data = {};
		data.address = JSON.parse(JSON.stringify(this.defaultAssressInfo));
		data.cartIds = this.cartIds;
		data.couponId = this.couponId;
		delete data.address.employeeId;
		delete data.address.id;
		delete data.address.isDefault;
		var res = await getReqOrderEnterprie(data);
		if(res.isSuccess){
			// 提交完订单之后重新调获取购物车列表数据
			this.getCatList();
			// 跳转到我的订单页面 
			window.open(`${this.preUrlOld}/HolidayWelfare/BillPay?official_jrfl1`,'_blank');
		}else{
			this.$message.error('提交订单失败');
		}
	},
	
	// 新增地址
	addAddressBtn(){
		this.addAddressDialog.show = true;
		this.getAddressList();
	},
	// 获取地址
	async getAddressList(){
		var res = await getAddress();
		if(res.isSuccess){
			let addressList = res.data;
			let _this = this;
			addressList.forEach(function(item){
				if(item.isDefault){
					_this.defaultAssressInfo = item;
				}
			})
			this.addressList = addressList;
		}else{
			this.$message(res.msg);
		}
	},
	// 地址选择发生改变
	handleChangeAddress (value) {
		this.addAddressForm.province = value[0];
		this.addAddressForm.city = value[1];
		this.addAddressForm.region = value[2];
		this.addAddressForm.town = value[3];
	},
	// 编辑地址发生变化
	handleChangeEditAddress(value){
		this.editAddressForm.province = value[0];
		this.editAddressForm.city = value[1];
		this.editAddressForm.region = value[2];
		this.editAddressForm.town = value[3];
	},
	// 省市区懒加载获取数据列表
	async selectLoadLoad (node, resolve) {//懒加载
		var _this = this;
		const  {level} = node;
		if(level == 0){//加载省级列表
			var res = await getSelectCityLoad(0);
			if(res.isSuccess){
				const nodes = res.data.map(item=>({
					id: item.code,
					value: item.fullName,
					label: item.fullName,
					leaf: level >= 3,
				}));
				// 通过resolve将子节点数据返回，通知组件数据加载完成
				resolve(nodes);
			}else{
				this.$message(res.msg);
			}
		}
		else if(level == 1 || level == 2 || level == 3) {//加载 市 区/县 街道
			const value = node.data.id;
			var res = await getSelectCityLoad(value);
			if(res.isSuccess){
				const nodes = res.data.map(item=>({
					id: item.code,
					value: item.fullName,
					label: item.fullName,
					leaf: level >= 3,
				}));
				resolve(nodes);
			}else{
				this.$message(res.msg);
			}
		}
	},
	// 保存地址
	saveAddressBtn(){
		let _this = this;
		let addAddressForm = JSON.parse(JSON.stringify(_this.addAddressForm))
		addAddressForm.isDefault = true;
		_this.$refs.addAddressFormRef.validate( async function (val) {
			if(!val) return;
			if(_this.lock) return;
			_this.lock = true;
			let res = await saveAddress(addAddressForm)
			if(res.isSuccess){
				_this.getAddressList();
				_this.$refs['addAddressFormRef'].resetFields();
				_this.$message.success(res.msg);
			}else{
				_this.$message.error(res.msg);
			}
			_this.lock = false;
		})
	},
	// 设为当前地址
	async setDefaultAddBtn(row){
		let data = JSON.parse(JSON.stringify(row));
		data.isDefault = true;
		var res = await saveAddress(data);
		if(res.isSuccess){
			this.getAddressList();
			this.$message.success(res.msg);
		}else{
			this.$message.error(res.msg);
		}
	},
	// 修改地址
	editAddressBtn(row){
		let editAddressForm = JSON.parse(JSON.stringify(row));
		editAddressForm.addressValue = [editAddressForm.province,editAddressForm.city,editAddressForm.region,editAddressForm.town];
		this.editAddressForm = editAddressForm;
		this.editAddDialog.show = true;
	},
	// 修改弹窗 确定地址
	async editAddDialogConfirm(){
		let editAddressForm = JSON.parse(JSON.stringify(this.editAddressForm));
		delete editAddressForm.addressValue;
		let _this = this;
		_this.$refs.editAddressFormRef.validate( async function (val) {
			if(!val) return;
			if(_this.lock) return;
			_this.lock = true;
			var res = await saveAddress(editAddressForm);
			if(res.isSuccess){
				_this.getAddressList();
				_this.editAddDialog.show = false;
				_this.$message.success(res.msg);
			}else{
				_this.$message.error(res.msg);
			}
			_this.lock = false;
		})
	},
	// 删除地址
	delAddressBtn(row){
		this.delAddDialog.show = true;
		this.delAddressId = row.id;
	},
	// 删除地址弹窗 确认按钮
	async delAddDialogConfirm(){
		//console.log(this.delAddressId)
		let res = await delAddress(this.delAddressId);
		if(res.isSuccess){
			this.getAddressList();
			this.$message.success(res.msg);
			// 如果删除地址与默认地址id一致，清空默认地址数据
			if(this.delAddressId == this.defaultAssressInfo.id){
				this.defaultAssressInfo = {};
			}
			this.delAddDialog.show = false;
		}else{
			this.$message.error(res.msg);
		}
	},	
  },
};
</script>

<style scoped lang="less">
.wrap{
  /deep/.top_search .row_company{
	margin-left: 670px;
  }
  width: 100%;
  background: #fbf8fd;
  .welfareBreadcrumb{
	background: #fbf8fd;
	margin-top: 0 !important;
	padding: 24px 0;
  }
  .con-body{
	width: 1200px;
	margin: 0 auto;
    background-color: #fbf8fd;
    border-radius: 5px;
    position: relative;
	padding-bottom: 40px;
	.car_table_box{
		padding:24px;
		background: #fff;
		margin-bottom: 24px;
		border-radius: 8px 8px 8px 8px;
	}
		// 购物车为空
    .shopping_car_empty{
      height: 790px;
      display: flex;
      justify-content: center;
      .img{
        width: 450px;
        height: 450px;
        background: url('/Images/holidayWelfare/pic_gwc.png');
      }
    }
    .info_wrap{
      display: flex;
      .info_right{
        margin-left: 12px;
        .info_header{
          color: #333333;
          font-size: 16px;
        }
        .info_code{
          color: #999999;
          font-size: 12px;
        }
		.tag{
			.el-dropdown{
				color:#66b1ff;
			}
		}
      }
    }
	// 固定脚部
	.fix_footer{
		padding: 15px 0;
		display: flex;
		justify-content: space-between;
		.bottom_box{
			margin-right: 29px;
			display: flex;
			justify-content:flex-end;
			align-items: center;
			font-size: 16px;
			color: #333;
			.red{
				color: #F5222D;
			}
			.fz{
				font-size: 22px;
				font-weight: 700;
			}
			/deep/.el-button--danger{
				background-color: #F5222D;
				margin-left: 30px;
				width: 136px;
				font-weight: bold;
				color: #fff;
			}
		}
		.footer_text{
			p{
				font-size: 14px;
				color: #F5222D;
				line-height: 40px;
			}
		}
	}
  }
}

.el-tag.el-tag--info{
  color: #59B0EC;
  font-size: 12px;
}

/* 计数器 加减号按钮 */
.el-input-number__decrease, .el-input-number__increase{
  background: none !important;
}

.dialog_order {
	/* 地址 */
	.top_box{
		width:720px;
		background: #F4F7FC;
		padding:24px 0 24px 30px;
		margin-bottom: 24px;
		.address_box{
			margin-top: 24px;
			.label_text{
				float: left;
				width: 80px;
				font-size: 16px;
				color:#333;
			}
			.address_inf{
				margin-left: 92px;
				font-size: 16px;
				color:#333;
				p{
					margin-top: 16px;
					span{
						margin-left: 30px;
					}
				}
			}
		}
		
	}
	/* 订单列表 */
	.table_box{
		width:720px;
		background: #F4F7FC;
		padding:25px 30px;
		margin-bottom: 24px;
		.table_box_head{
			margin-bottom: 24px;
			.current_company{
				display: flex;
				line-height: 56px;
				font-size: 16px;
				color: #333333;
				font-weight: 400;
				margin-bottom: 24px;
				.img_box{
					width: 56px;
					height: 56px;
					border-radius: 50%;
					position: relative;
					overflow: hidden;
					cursor: pointer;
					font-size: 14px;
					font-weight: 600;
					color: #fff;
					margin-right: 8px;
					img{
						width: 56px;
						height: 56px;
						border-radius: 50%;
					}
					.Auth{
						position: absolute;
						left: 0;
						width: 56px;
						bottom: 0;
						height: 28px;
						line-height: 28px;
						background: #000;
						opacity: .3;
						border-radius: 0 0 100% 100%;
						display: flex;
						justify-content: center;
					}
				}
				
			}
			.type_header{
				font-size: 16px;
				color: #333333;
				span{
					width: 64px;
					display: inline-block;
					text-align-last: justify;
				}
			}
		}
		.coupon_box{
			margin-top: 20px;
			font-size: 16px;
			color: #333;
		}
	}
	.fix_footer_box{
		background-color: #E6F7FF;
		display: flex;
		justify-content: center;
		padding: 25px 0;
		color: #333;
		font-size: 16px;
		.item_info{
			margin-left: 30px;
		}
		.fw{
			font-weight: 700;
			font-size: 22px;
		}
		.red{
			color: #F5222D;
			font-size: 22px;
		}
		/deep/.el-button--primary{
			background-color: #F5222D;
			border: 1px solid  #fff;
		}
	}
	/deep/.el-dialog__footer{
		/deep/.dialog-footer{
			/deep/.el-button--primary{
				background-color: #F5222D;
				border: 1px solid  #fff;
				border-radius: 25px;
			}
		}
	}
}


/* 删除弹框的 提交订单 确定按钮  */
/deep/.dialog_del .el-button--primary,
/deep/.dialog_order .el-button--primary{
  background-color: #F5222D;
  border: 1px solid  #fff;
	&:hover{
  background-color: #F7474F;
	}
}
/deep/.dialog_order .el-button--primary,
/deep/.dialog_order .el-button--default{
	border-radius: 25px;
  width: 136px;
  height: 40px;
}

.el-input--small .el-input__inner{
  color: #333;
}
.el-table{
	color: #333333;
	td,th{
		padding: 16px 0 !important;
	}
	
	.el-checkbox__inner:hover{
  		border-color: #F5222D;
	}
	/* 表格过期样式 */
	.gray_class{
  	color: #E0E0E0 !important;
	}
} 

.not_valid_tags{
  background-color: #f5f5f5 !important;
  color: #E0E0E0 !important;
}
.not_valid_img::after{
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  content: '失效';
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.checkBox{
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid #EAEAEA;
  background: #fff;
	p{
  	padding: 0 60px 0 20px;
	}
	#check{
		width: 79px;
		height: 30px;
		border-radius: 35px;
		line-height: 30px;
		padding: 0;
		text-align: center;
		color: #ff5f58;
		border: 1px solid rgba(255, 95, 88, 1);
		background-color: transparent;
		font-size: 12px;
		cursor: pointer;
		&:hover{
			background-color: #ff5f58;
  		color: #fff;
		}
	}

}

.bottom_box1{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
	/* 协议按钮变红 */
	.el-button--primary{
		background-color: #F5222D !important;
		border-color: #F5222D !important;
	}
	.el-button--primary:hover{
		background-color: #F7474F !important;
		border-color: #F7474F !important;
	}
	.el-checkbox__inner:hover{
  	border-color: #F5222D;
	}
	.el-checkbox__input.is-checked{
		.el-checkbox__inner{
			background-color: #F5222D;
			border-color: #F5222D;
		}
	}
}


// 表格多选框
/deep/.el-checkbox__input.is-checked .el-checkbox__inner, /deep/.el-checkbox__input.is-indeterminate .el-checkbox__inner{
	border-color: #F5222D !important;
}
/deep/.el-checkbox__input.is-checked .el-checkbox__inner, /deep/.el-checkbox__input.is-indeterminate .el-checkbox__inner{
	border-color: #F5222D !important;
	background-color: #F5222D !important;
}
/deep/.el-checkbox__inner:hover {
	border-color: #F5222D !important;
}
/* 收货地址弹窗 */
.add_addres_dialog .top_box .box_header,
.add_addres_dialog .bottom_box .box_header
{
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.add_addres_dialog .top_box .box_header .spot,
.add_addres_dialog .bottom_box .box_header .spot{
  display: inline-block;
  width: 2px;
  height: 13px;
  background-color: #036EB7;
  margin-right: 10px;
}

</style>
